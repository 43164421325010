<template>
  <main
    class="
      max-w-4xl
      grid grid-cols-1
      mx-5
      px-0
      py-5
      pt-0
      bg-white
      rounded-3xl
      overflow-y-hidden
      bg-opacity-90
      md:mx-auto
      min-h-screen
      relative
    "
  >
    <transition name="fade">
      <div
        v-if="isLoading"
        class="absolute bg-gray-200 inset-0 h-full pt-9 z-10 overflow-hidden"
      >
        <svg
          class="
            max-w-full max-h-96
            m-auto
            animate-pulse
            text-pink-500
            self-center
          "
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="0.3"
          stroke-linecap="round"
          stroke-linejoin="round"
          display="block"
        >
          <path
            d="M17.79 3.603A1 1 0 0 0 16.99 2H10.58a1 1 0 0 0-.867.502l-4.853 8.456a1 1 0 0 0 .868 1.497H9L5.916 20.52c-.445 1.02.76 1.953 1.57 1.215L20 9.331h-6.539l4.328-5.728z"
          />
        </svg>
      </div>
    </transition>

    <transition name="fade">
      <DeviceInfo
        v-if="!error && deviceInfo && !isLoading"
        :info="deviceInfo"
      />
      <div v-else-if="!isLoading">
        <h1 class="my-5 pt-9 uppercase text-pink-500 text-5xl font-extralight">
          {{ error }} ({{ getDeviceID() }})
        </h1>
        <p
          class="
            text-xl
            my-9
            py-9
            px-9
            uppercase
            text-pink-500
            italic
            font-extralight
            tracking-widest
          "
        >
          {{ errorQuote }}
        </p>
        <img class="block max-h-600 mx-auto" src="./assets/statue.png" />
      </div>
    </transition>
  </main>
</template>

<script>
  import DeviceInfo from './components/Device-Info.vue';
  import axios from 'axios';
  import errorQuotes from './assets/quotes.json';

  export default {
    components: {
      DeviceInfo,
    },

    data() {
      return {
        deviceInfo: null,
        error: null,
        errorQuote: null,
        isLoading: true,
      };
    },

    async mounted() {
      // Pull GET param from URL
      const id = this.getDeviceID();
      // Axios fetch data from Google Apps Script
      const { data, error } = await this.fetchDeviceInfo(id);

      this.deviceInfo = data;
      this.error = error;
      this.errorQuote = errorQuotes
        ? errorQuotes[Math.floor(Math.random() * errorQuotes.length)]
        : null;

      document.title = `${data?.device_name ?? 'Woop Woop'} - Device Previewer`;

      this.$nextTick(function () {
        this.isLoading = false;
      });

      //Allow for upgraded styling if backdrop-filter is supported
      if (CSS.supports('backdrop-filter: blur(20px)')) {
        this.$el.classList.remove('bg-opacity-90');
        this.$el.classList.add(
          'backdrop-filter',
          'backdrop-blur-3xl',
          'bg-opacity-50'
        );
      }
    },

    methods: {
      getDeviceID() {
        let urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('id')) {
          return urlParams.get('id');
        }
      },
      async fetchDeviceInfo(id) {
        try {
          const response = await axios.get(
            `${
              import.meta.env.VITE_DIRECTUS_DATA_URL
            }?filter[device_id][_eq]=${id}&fields[]=*.*`
          );

          if (Object.entries(response.data.data).length == 0) {
            return {
              data: undefined,
              error: 'Device ID not found',
            };
          }

          return {
            data: response.data.data[0],
            error: undefined,
          };
        } catch (error) {
          console.error(error);
          return {
            data: undefined,
            error: error,
          };
        }
      },
    },
  };
</script>

<style lang="postcss">
  body {
    background: url('https://cdn.greatnews.life/device-previewer-images/background.jpg');
    background-attachment: fixed;
    background-size: cover;
    background-position: top left;
  }

  html {
    background: #f677b3;
  }

  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #0c1e30;

    @apply py-6;
  }

  .fade-enter-active {
    transition: all 0.3s ease-out;
  }
  .fade-leave-active {
    transition: all 0.8s linear;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  @font-face {
    font-family: 'Libre Baskerville';
    src: url('../public/fonts/LibreBaskerville-Italic.ttf');
  }
</style>
