<template>
  <div>
    <!-- Preview image-->
    <figure class="relative w-full h-80 md:h-96">
      <img
        v-bind:src="getImage()"
        class="w-full h-full object-cover overflow-y-hidden"
      />
      <div
        class="
          absolute
          inset-0
          bg-gradient-to-b
          from-transparent
          to-pink-400
          mix-blend-lighten
          opacity-60
        "
      ></div>
    </figure>

    <h2
      class="
        mx-5
        my-9
        text-3xl
        md:text-5xl
        tracking-widest
        uppercase
        font-extralight
      "
    >
      {{ info['device_name'] }}
    </h2>

    <!-- Primary details -->
    <section
      id="primary-details"
      class="
        p-1
        pb-0
        mx-5
        mb-5
        border-pink-500 border-4
        rounded-xl
        border-opacity-40
        text-left
        grid-cols-1
        gap-9
        md:space-y-0 md:grid-cols-2 md:grid
      "
    >
      <!-- ID, model, status, and assignee -->
      <section class="text-center md:text-left">
        <section class="mb-2">
          <p class="text-3xl inline-block align-middle">
            {{ info.device_id }}
          </p>
          <p
            class="
              detail-value
              relative
              rounded-xl
              pt-0.5
              pl-5
              pr-6
              mt-0
              mb-2
              mx-8
              md:mr-0 md:ml-2 md:inline-block
              text-sm
            "
            :class="statusClass"
          >
            {{ info.status }}
          </p>
        </section>

        <p class="uppercase -mt-1">{{ info.model }}</p>

        <p class="detail-key justify-items-center">Assigned To</p>
        <p class="detail-value -mt-2 text-2xl">
          {{ info.assignee?.name ?? 'N/A' }}
        </p>
      </section>

      <!-- Description -->
      <section>
        <p
          id="description"
          class="
            text-sm
            md:text-xl
            leading-snug
            md:leading-tight
            p-2
            md:p-0 md:pr-2
            italic
            text-justify text-gray-700
          "
        >
          {{ fullDescription }}
        </p>
      </section>
    </section>

    <!-- Other details -->
    <ul
      class="
        text-left
        mx-5
        grid grid-flow-row-dense grid-cols-1
        md:grid-cols-2
        gap-x-4
      "
    >
      <li
        v-for="(value, property) of filterColumns(info)"
        :key="property"
        :class="{
          'col-span-2': property === `updates`,
        }"
      >
        <p
          class="
            p-1
            bg-p bg-pink-500 bg-opacity-30
            rounded-t-xl
            border-transparent border-4 border-b-0
            uppercase
          "
        >
          {{ property }}
        </p>

        <ol
          v-if="property === `updates`"
          class="
            updates-list
            p-1
            mb-2
            text-sm
            md:text-xl
            rounded-b-xl
            border-pink-500 border-4 border-t-0 border-opacity-20
          "
        >
          <li v-for="update of value" :key="update">
            <time v-show="update.date" class="pr-2"> {{ update.date }}</time>
            <span> {{ update.update ?? '' }} </span>
          </li>
        </ol>
        <p
          v-else
          class="
            p-1
            mb-2
            text-sm
            md:text-xl
            rounded-b-xl
            border-pink-500 border-4 border-t-0 border-opacity-20
          "
        >
          {{ value }}
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
  /**
   * A component for displaying GNL device information in a prettier format than a spreadsheet
   *
   * @displayName Device Info
   */
  export default {
    props: {
      /**
       * The fetched device information from Google Sheets spreadsheet
       */
      info: Object,
    },
    computed: {
      /**
       * The full summary made up of the combined description and notes fields
       * from Directus
       *
       * @returns {String} The full description
       */
      fullDescription() {
        let result = '';

        if (this.info.description) {
          result += this.info.description.match(/^$|N\/?A/)
            ? ''
            : this.info.description
                .replace(/\s-\s?/g, '. ')
                .replace(/-\s?/g, '') + '. ';
        }

        if (this.info.notes) {
          result += this.info.notes + '. ';
        }

        result = result.replace(/\.{2,}/g, '.');

        return result;
      },
      statusClass() {
        const valueMap = new Map(
          Object.entries({
            great: 'bg-green-500',
            good: 'bg-blue-500 text-white',
            okay: 'bg-yellow-500',
            mediocre: 'bg-orange-500',
            bad: 'bg-red-500 text-white',
          })
        );
        let value = valueMap.get(this.info?.status) ?? 'text-gray-500';

        return value;
      },

      statusName() {
        switch (this.info?.status) {
          case '5':
            return 'Great';
            break;
          case '4':
            return 'Good';
            break;
          case '3':
            return 'Okay';
            break;
          case '2':
            return 'Mediocre';
            break;
          case '1':
            return 'Bad';
            break;
        }
      },
    },
    methods: {
      getImage() {
        return `${import.meta.env.VITE_DIRECTUS_IMAGE_URL}${
          this.info.image.id
        }`;
      },

      filterColumns(i) {
        const columnsToFilter =
          import.meta.env.VITE_DIRECTUS_EXCLUDED_FIELDS.split(',');

        let result = JSON.parse(JSON.stringify(i));

        Object.entries(i).forEach((property) => {
          let [key, value] = property;

          if (
            columnsToFilter.includes(key) ||
            (typeof value !== 'boolean' && !value)
          ) {
            delete result[key];
          } else if (key === 'updates') {
            result[key] = value?.map((update) => {
              return {
                date: update.Date,
                update: update.Update,
              };
            });
          }
          if (key == 'sort') {
            i['Device rank'] = value;

            delete result[key];
          } else if (typeof value === 'boolean') {
            i[key] = value ? 'Yes' : 'No';
          }
        });

        let f = {};

        Object.entries(result).forEach((property) => {
          let [key, value] = property;

          f[key.replace(/_/g, ' ')] = value;
        });

        return f;
      },
    },
  };
</script>

<style scoped lang="postcss">
  main {
    box-shadow: 0 0 35px -15px black;
  }

  #description {
    font-family: 'Libre Baskerville';
    hyphens: auto;
  }

  #primary-details {
    grid-template-columns: auto 1fr;
  }

  .detail-key {
    @apply uppercase mb-0 mt-2 text-sm;
  }
  .detail-value {
    @apply uppercase mb-0;
  }

  .updates-list > li:nth-child(2n) {
    @apply border-pink-500 border-opacity-50 border-t pt-1 mt-1;
  }
</style>
